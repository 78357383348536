import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />

    <div className='flex flex-col py-20 justify-center items-center space-y-5'>
      <h1 className='text-3xl'>404: Not Found</h1>
      <p>Go back to homepage.</p>
      <Link
        to="/"
        className="inline-block px-20 py-4 text-lg font-medium text-center text-white bg-primary shadow-xl hover:bg-green-700 rounded-2xl"
      >
        Homepage
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
